import React from 'react'
import { client, firm, invoiceScreenshot } from '../../assets/images';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Overview = () => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 10,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    autoplay: true,
  };

  const services = [
    {
      img: invoiceScreenshot,
      title: 'Invoicing',
    },
    {
      img: firm,
      title: 'Firm Management',
    },
    {
      img: client,
      title: 'Client Management',
    }
  ];


  return (
    <div className='m-0 p-0 my-5 py-5 container-fluid overiew-main-contianer'>
      <div className='container align-items-center d-flex justify-content-center align-items-center flex-column' >
        <h2 className='section-title text-dark text-center py-3 d-inline'>Quick Software Overview</h2>
        <div className={`my-5 py-5`}>
          <Slider {...settings} className='d-flex justify-content-center align-items-center' style={{ width: "100vw", maxWidth: "100vw" }}>
            {
              services.map((data, index) => (
                <div className='ms-5 ps-5 w-100 d-flex justify-content-center align-items-center flex-column' key={index}>
                  <img src={data.img} alt='' className='d-block' style={{ borderRadius: "10px", boxShadow: '0px 6px 12px 0px #00497628', width: '70%', height: "70%" }} />
                  <div className='w-100 d-flex justify-content-center align-items-center me-5 pe-5 text-dark py-4'>
                    <span className='fw-bold fs-1 text-secondary'>{index < 9 ? (`0${index + 1}`) : (index + 1)}</span>
                    <span className='fw-bold ms-3 fs-4' style={{ color: "#10105b" }}>{data.title}</span>
                  </div>
                </div>
              ))
            }
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default Overview
