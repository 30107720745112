import React, { useEffect, useState } from 'react'
import { register } from '../assets/images'
import { Col, Row } from 'react-bootstrap';
import { RoundedButton } from '../components/Rounded-Button';

const Subscribe = () => {

    const [name, setName] = useState('')

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div>
            <Row style={{ paddingRight: "10%", paddingLeft: "10%", paddingTop: "2%" }}>
                <Col sm={6}>
                    <img src={register} alt='' style={{ borderRadius: "10px", height: "90%", width: "100%" }} />
                </Col>
                <Col sm={6} className='py-5'>
                    <div className='text-start text-dark bg-white p-3 rounded' style={{ marginLeft: "-15%", boxShadow: "0px 0px 10px 0px #00000030", }}>
                        <Row>
                            <Col sm={12}>
                                <h2 className='heading'>Subscription Form</h2>
                            </Col>
                        </Row>
                        <div className='m-0 p-0'>
                            <Row className='ms-2 fs-4 my-2 text-secondary'>Plan: $30.00</Row>
                            <hr />
                            <form>
                                <Row>
                                    <Col sm={12} className="mb-2">
                                        <div className="w-100 p-2 label-float">
                                            <input type="text" className='w-100 p-2' name="name" value={name} onChange={(e) => setName(e.target.value)} placeholder=" " required autoComplete='off' />
                                            <label className='ms-3'>Company Name</label>
                                        </div>
                                    </Col>
                                    <Col sm={6} className="mb-2">
                                        <div className="w-100 p-2 label-float">
                                            <input type="text" className='w-100 p-2' name="name" value={name} onChange={(e) => setName(e.target.value)} placeholder=" " required autoComplete='off' />
                                            <label className='ms-3'>No. of users</label>
                                        </div>
                                    </Col>
                                    <Col sm={6} className="mb-2">
                                        <div className="w-100 p-2 label-float">
                                            <input type="text" className='w-100 p-2' name="name" value={name} onChange={(e) => setName(e.target.value)} placeholder=" " required autoComplete='off' />
                                            <label className='ms-3'>Website</label>
                                        </div>
                                    </Col>
                                    <Col sm={6} className="mb-2">
                                        <div className="w-100 p-2 label-float">
                                            <input type="email" className='w-100 p-2' name="name" value={name} onChange={(e) => setName(e.target.value)} placeholder=" " required autoComplete='off' />
                                            <label className='ms-3'>Email</label>
                                        </div>
                                    </Col>
                                    <Col sm={6} className="mb-2">
                                        <div className="w-100 p-2 label-float">
                                            <input type="number" className='w-100 p-2' name="name" value={name} onChange={(e) => setName(e.target.value)} placeholder=" " required autoComplete='off' />
                                            <label className='ms-3'>Phone</label>
                                        </div>
                                    </Col>
                                </Row>
                                <hr />
                                <div>Address</div>
                                <Row>
                                    <Col sm={6} className="mb-2">
                                        <div className="w-100 p-2 label-float">
                                            <input type="text" className='w-100 p-2' name="name" value={name} onChange={(e) => setName(e.target.value)} placeholder=" " required autoComplete='off' />
                                            <label className='ms-3'>Address</label>
                                        </div>
                                    </Col>
                                    <Col sm={6} className="mb-2">
                                        <div className="w-100 p-2 label-float">
                                            <input type="text" className='w-100 p-2' name="name" value={name} onChange={(e) => setName(e.target.value)} placeholder=" " required autoComplete='off' />
                                            <label className='ms-3'>Country</label>
                                        </div>
                                    </Col>
                                    <Col sm={6} className="mb-2">
                                        <div className="w-100 p-2 label-float">
                                            <input type="text" className='w-100 p-2' name="name" value={name} onChange={(e) => setName(e.target.value)} placeholder=" " required autoComplete='off' />
                                            <label className='ms-3'>State</label>
                                        </div>
                                    </Col>
                                    <Col sm={6} className="mb-2">
                                        <div className="w-100 p-2 label-float">
                                            <input type="text" className='w-100 p-2' name="name" value={name} onChange={(e) => setName(e.target.value)} placeholder=" " required autoComplete='off' />
                                            <label className='ms-3'>City</label>
                                        </div>
                                    </Col>
                                    <Col sm={6} className="mb-2">
                                        <div className="w-100 p-2 label-float">
                                            <input type="text" className='w-100 p-2' name="name" value={name} onChange={(e) => setName(e.target.value)} placeholder=" " required autoComplete='off' />
                                            <label className='ms-3'>Pincode / Zipcode</label>
                                        </div>
                                    </Col>
                                </Row>
                                <hr />
                                <div className='d-flex justify-content-end align-items-end'>
                                    <RoundedButton type="submit">Subscribe</RoundedButton>
                                </div>
                            </form>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Subscribe
