import React, { useState } from 'react';

import { IoMdAdd } from 'react-icons/io';
import { IoRemoveOutline } from 'react-icons/io5';

const Faq = () => {

    const [selected, setSelected] = useState(0);

    const dummyArray = [
        {
            id: 1,
            question: "What is a CRM system?",
            answer: "A CRM system is a software solution that helps businesses manage interactions and relationships with their customers. It centralizes customer data, facilitates communication, and enhances customer service.",
        },
        {
            id: 2,
            question: "How can a CRM system benefit my business?",
            answer: "A CRM system improves customer engagement, helps track leads and sales, automates marketing campaigns, and provides insights for better decision-making. It enhances overall customer experience and boosts business growth."
        },
        {
            id: 3,
            question: "Is data security ensured in the CRM system?",
            answer: "Yes, data security is a priority. Our CRM system employs encryption, access controls, and regular data backups to safeguard your customer information.",
        },
        {
            id: 4,
            question: "What is an HRMS?",
            answer: "An HRMS is a software platform designed to streamline human resource processes within an organization. It encompasses functions like employee management, payroll, attendance tracking, recruitment, and performance evaluation."
        },
        {
            id: 5,
            question: "How does an HRMS improve workforce management?",
            answer: "An HRMS automates manual HR tasks, tracks employee attendance and leave, manages payroll and benefits, and provides a centralized platform for employee records. This leads to increased efficiency and accuracy in HR processes."
        },
        {
            id: 6,
            question: "Can the HRMS integrate with other systems we use?",
            answer: "Yes, our HRMS is designed to integrate with various systems, including payroll software, time and attendance tracking tools, and even CRM systems. This integration ensures seamless data flow across departments."
        }
    ];

    return (
        <section className="faq-section pb-5">
            <div className="container mb-5">
                <div className="row">
                    <div className="col-md-6 offset-md-3">

                        <div className="faq-title text-center pb-3">
                            <h2 className='dark-title'>FAQ</h2>
                        </div>
                    </div>
                    <div className="col-md-6 offset-md-3">
                        <div className="faq" id="accordion">
                            {
                                dummyArray.map((data, index) => (
                                    <div className="card my-3" key={index}>
                                        <div className="card-header" id="faqHeading-1" onClick={() => setSelected(index)}>
                                            <div className="mb-0">
                                                <h5 className="faq-title">
                                                    <span className="badge">
                                                        {
                                                            index === selected ? (
                                                                <IoRemoveOutline className=' text-white' style={{ cursor: "pointer" }} />
                                                            ) : (
                                                                <IoMdAdd className=' text-white' style={{ cursor: "pointer" }} />
                                                            )
                                                        }
                                                    </span>
                                                    <span className='dark-title'>
                                                        {data.question}
                                                    </span>
                                                </h5>
                                            </div>
                                        </div>
                                        {
                                            index === selected ? (
                                                <div id="faqCollapse-1">
                                                    <div className="card-body">
                                                        <p className='text-dark'>{data.answer}</p>
                                                    </div>
                                                </div>
                                            ) : null
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Faq
