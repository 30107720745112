import React, { useCallback, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Card from '../components/card';
import { BiStore } from 'react-icons/bi';
import { LiaFileInvoiceDollarSolid } from 'react-icons/lia';
import { MdLeaderboard } from 'react-icons/md';
import { RiUserSettingsFill } from 'react-icons/ri';
import { RoundedButton } from '../components/Rounded-Button';
import { business, featuresCover } from '../assets/images';
import '../assets/css/icons.css';
import { Tilt } from 'react-tilt';
import { Link } from 'react-router-dom';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import Subheader from '../components/subheader';
import Slider from 'react-slick';

const Features = () => {

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const particlesInit = useCallback(async engine => {
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        // await console.log(container); 
    }, []);

    const allFeatures = [
        {
            icon: <LiaFileInvoiceDollarSolid />,
            title: 'Invoicing',
            desc: 'helps businesses manage interactions and relationships with their customers. It centralizes customer data, facilitates communication, and enhances customer service.'
        },
        {
            icon: <MdLeaderboard />,
            title: 'Lead Management',
            desc: 'Assists businesses in capturing, tracking, and converting potential leads into customers. It helps streamline lead nurturing, follow-up, and analysis.'
        },
        {
            icon: <RiUserSettingsFill />,
            title: 'HRM',
            desc: 'Designed to streamline human resource processes within an organization. It encompasses functions like employee management, payroll, attendance tracking, recruitment, and performance evaluation.'
        },
        {
            icon: <BiStore />,
            title: 'Firm Management',
            desc: ' Helps professional service firms efficiently manage their cases, clients, documents, and interactions'
        },
        {
            icon: <BiStore />,
            title: 'Client Management',
            desc: 'Centralizes case information, facilitates document sharing, and improves client engagement. Also optimizes task management, deadline tracking, and enhances overall firm productivity.'
        },
    ];

    const extraFeatures = [
        {
            title: 'Advanced Anlytics Review',
            desc: 'Seeing rather her you not esteem men settle genius excuse. Deal say over you age from. Comparison new ham melancholy.'
        },
        {
            title: 'Search Engine Optimization',
            desc: 'Seeing rather her you not esteem men settle genius excuse. Deal say over you age from. Comparison new ham melancholy.'
        }
    ];

    const features = [
        {
            title: 'Create Invoices',
            icon: 'zimed-icon-responsive',
            delay: '000ms'
        },
        {
            title: 'Manage Leads',
            icon: 'zimed-icon-computer-graphic',
            delay: '100ms'
        },
        {
            title: 'HRM',
            icon: 'zimed-icon-development1',
            delay: '200ms'
        },
        {
            title: 'Manage Firms',
            icon: 'zimed-icon-development',
            delay: '300ms'
        },
        {
            title: 'Daily Update',
            icon: 'zimed-icon-development',
            delay: '400ms'
        },
    ];



    const defaultOptions = {
        reverse: true,
        max: 30,
        perspective: 1000,
        scale: 1.01,
        speed: 1000,
        transition: true,
        axis: null,
        reset: true,
        easing: "cubic-bezier(.03,.98,.52,.99)",
    };

    const allOptions = {
        fullScreen: false,
        background: { color: "#fff" },
        particles: {
            number: {
                value: 10,
                density: {
                    enable: true,
                    value_area: 1500
                }
            },
            color: { value: "#2E56F9" },
            shape: {
                type: "square",
                stroke: { width: 0, color: "#000000" },
                polygon: { nb_sides: 5 }
            },
            opacity: {
                value: 0.6,
                random: true,
                anim: {
                    enable: false,
                    speed: 100,
                    opacity_min: 0.1,
                    sync: false
                }
            },
            size: {
                value: 30,
                random: true,
                anim: {
                    enable: false,
                    speed: 2,
                    size_min: 0.1,
                    sync: false
                }
            },
            line_linked: {
                enable: false,
                distance: 300,
                color: "#ffffff",
                opacity: 0,
                width: 0
            },
            move: {
                enable: true,
                speed: 2,
                direction: "top",
                straight: false,
                out_mode: "out",
                bounce: true,
                attract: {
                    enable: false,
                    rotateX: 600,
                    rotateY: 1200
                }
            }
        },
        interactivity: {
            detect_on: "canvas",
            events: {
                onhover: {
                    enable: false,
                    mode: "repulse"
                },
                onclick: {
                    enable: true,
                    mode: "push"
                },
                resize: true
            },
            modes: {
                grab: {
                    distance: 800,
                    line_linked: { opacity: 1 }
                },
                bubble: {
                    distance: 790,
                    size: 79,
                    duration: 2,
                    opacity: 0.8, speed: 3
                },
                repulse: {
                    distance: 400,
                    duration: 0.4
                },
                push: { particles_nb: 1 },
                remove: { particles_nb: 1 }
            }
        },
        retina_detect: true
    }


    // console.log("screenwidth", screenWidth);
    const settings = {
        dots: true,
        infinite: true,
        speed: 10,
        slidesToShow: screenWidth > 1200 ? 5 : screenWidth > 1000 ? 3 : screenWidth > 720 ? 2 : 1,
        slidesToScroll: 1,
        centerMode: false,
        autoplay: true,
    };

    const VerticalCarouselsettings = {
        dots: false,
        infinite: true,
        vertical: true,
        verticalSwiping: true,
        slidesToShow: 3, // Number of items to show at once
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000, // Auto-play interval in milliseconds
    };
    return (
        <div className='features-container'>
            <Subheader bgImg={featuresCover} title="Features" />
            <div style={{ position: 'relative' }}>
                <Particles
                    className='particles-main-container'
                    style={{ minHeight: "250vh" }}
                    id="tsparticles"
                    init={particlesInit}
                    loaded={particlesLoaded}
                    options={allOptions}
                />
                <div className='features-main-container container-fluid' style={{ position: 'absolute', top: 100 }}>
                    <div className=' container-fuild'>
                        <section className='container features-main'>
                            <Row>
                                <Col md={6} className='px-5 features-desc-section'>
                                    <h4 className='primary-title'>Our Features</h4>
                                    <h2 className='dark-title features-title'>Some of our main features</h2>
                                    <p className='paragraph mt-5'>
                                        Everything melancholy uncommonly but solicitude inhabiting projection off. Connection stimulated estimating excellence an to impression. ladies she basket season age her uneasy saw. Discourse unwilling am no described.
                                    </p>
                                    <p className='paragraph mt-2 mb-5'>
                                        Frequently partiality possession resolution at or appearance unaffected he me. Engaged its was evident pleased husband. Ye goodness felicity do disposal dwelling no. First am plate jokes.
                                    </p>
                                    <div className='get-started-btn'>
                                        <RoundedButton>Get Started</RoundedButton>
                                    </div>
                                </Col>
                                <Col md={6} className='px-5'>
                                    <Slider className='' {...VerticalCarouselsettings}>
                                        {
                                            allFeatures.map((data, index) => (
                                                <div className='mb-5 vertical-carousel-item' key={index}>
                                                    <Card>
                                                        <Row>
                                                            <Col sm={1}>
                                                                <span className='icon' style={{ fontSize: "3rem" }}>{data.icon}</span>
                                                            </Col>
                                                            <Col sm={11} className='ps-5 px-4'>
                                                                <h4 className='dark-subtitle'>{data.title}</h4>
                                                                <p className='paragraph mt-3'>{data.desc}</p>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </div>
                                            ))
                                        }
                                    </Slider>
                                </Col>
                            </Row>
                        </section>
                    </div>

                    <div className="cta-three my-5 py-5">
                        <img src="/assets/images/shapes/cta-1-shape-2.png" alt="" className="cta-three__shape-2" />
                        <img src="/assets/images/shapes/cta-1-shape-3.png" alt="" className="cta-three__shape-3" />
                        <div className="container">
                            <img src="/assets/images/shapes/cta-1-shape-1.png" alt="" className="cta-three__shape-1" />
                            <section className="service-one" id="features">
                                <div className="container">
                                    <div className=" text-center">
                                        <span className="block-title__bubbles"></span>
                                        <p className='text-white'>Checkout Our Features</p>
                                        <h2 className='title text-white fs-1'>The Only App You’ll Need</h2>
                                    </div>
                                    <div className="row features-slider">
                                        <Slider {...settings}>
                                            {
                                                features.map((data, index) => (
                                                    <div className="col-sm-12 col-md-4 col-lg-2 service-one__col wow fadeInUp" data-wow-duration="1500ms" data-wow-delay={`${data.delay}`} key={index}>
                                                        <Tilt options={defaultOptions}>
                                                            <div className="service-one__single">
                                                                <i className={data.icon}></i>
                                                                <h3>{data.title}</h3>
                                                            </div>
                                                        </Tilt>
                                                    </div>

                                                ))
                                            }
                                        </Slider>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>

                    <div className='container-fuild'>
                        <section className='container my-5 py-5'>
                            <Row>
                                <Col sm={12} md={6} lg={4} className='px-5'>
                                    <Card>
                                        <h6 className='primary-title'>Get Started</h6>
                                        <h5 className='dark-title'>To check all features please register with us</h5>
                                        <p className='paragraph'>Be a part of our business</p>
                                        <Link to="/">
                                            <RoundedButton>Get Started</RoundedButton>
                                        </Link>
                                    </Card>
                                </Col>
                                <Col sm={12} md={6} lg={3}>
                                    <img src={business} alt='' className='rounded features-img' />
                                </Col>
                                <Col sm={12} md={12} lg={5} className='px-5 feature-benifit'>
                                    <h5 className='primary-title'>BENIFIT YOUR BUSINESS</h5>
                                    <h3 className='dark-title'>Work smarter with powerful features</h3>
                                    <p className='paragraph'>
                                        One order all scale sense her gay style wrote. Incommode our not one ourselves residence. Shall there whose those stand she end. So unaffected partiality indulgence dispatched to of celebrated remarkably. Unfeeling are had allowance own perceived abilities. Unfeeling are had allowance own perceived abilities.
                                    </p>
                                    <div className='my-4'>
                                        {
                                            extraFeatures.map((data, index) => (
                                                <Row key={index}>
                                                    <Col sm={1}>
                                                        {/* <input type='checkbox' checked /> */}
                                                    </Col>
                                                    <Col sm={11}>
                                                        <div className='m-0 p-0 mb-3' key={index}>
                                                            <h6 className='dark-title'>{data.title}</h6>
                                                            <p className='paragraph'>{data.desc}</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            ))
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Features
