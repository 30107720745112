import React from 'react';
import Banner from './banner';
import Overview from './overview';
import Testimonial from './testimonial';
import Faq from './faq';

const Home = () => {
  return (
    <div className='home-main m-0 p-0'>
      <Banner />
      <Overview />
      <Testimonial />
      <Faq />
    </div>
  )
}

export default Home
