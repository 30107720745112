import React from 'react'
import { Route, Routes } from 'react-router-dom'
import About from '../pages/about'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import Home from '../pages/home/home'
import Features from '../pages/features'
import Pricing from '../pages/pricing'
import ContactUs from '../pages/contact-us'
import Subscribe from '../pages/subscribe'

const MainRoutes = () => {
    return (
        <>
            <Navbar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/features" element={<Features />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/contact" element={<ContactUs />} />
                <Route path="/subscribe" element={<Subscribe />} />
                <Route path="/about" element={<About />} />
            </Routes>
            <Footer />
        </>
    )
}

export default MainRoutes
