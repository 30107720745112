import React, { useEffect, useState } from 'react'
import Subheader from '../components/subheader'
import { ContactUsCover } from '../assets/images'
import Card from '../components/card'
import { Col, Row } from 'react-bootstrap'
import { HiEnvelope } from 'react-icons/hi2';
import { IoCall } from 'react-icons/io5';
import { RoundedButton } from '../components/Rounded-Button'
import { SuccessToast } from '../components/toast'

const ContactUs = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const contactElements = [
        {
            icon: <HiEnvelope />,
            title: 'Our Email',
            desc: 'info@cubicleweb.com'
        },
        {
            icon: <IoCall />,
            title: 'Phone',
            desc: '+13106518330'
        },
    ];

    const handleSubmit = (e) => {
        e.preventDefault();
        window.scrollTo(0, 0)
        // const frorm = {
        //     name: name,
        //     email: email,
        //     phone: phone,
        //     message: message
        // }
        setName('');
        setEmail('');
        setPhone('');
        setMessage('');
        SuccessToast("Form sumitted");
        alert('form submitted');
    };

    return (
        <>
            <Subheader bgImg={ContactUsCover} title="Contact Us" />
            <section className='container-fluid my-5 py-5'>
                <div className='container'>
                    <Row>
                        <Col lg={5}>
                            {
                                contactElements.map((data, index) => (
                                    <div className='mb-5' key={index}>
                                        <Card>
                                            <div className='d-flex justify-content-start align-items-start'>
                                                <span className='icon'>{data.icon}</span>
                                                <div className=' ps-5 px-4 w-75'>
                                                    <h6 className='dark-title'>{data.title}</h6>
                                                    <p className='paragraph'>{data.desc}</p>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                ))
                            }
                        </Col>
                        <Col lg={7} className='contact-us-form-main-container'>
                            <div>
                                <p className='paragraph mb-0'>Get in touch</p>
                                <h2 className='dark-title my-0'>Contact Us</h2>
                                <p className='paragraph'>Reach Out, We're Here for You!</p>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <Row>
                                    <Col sm={12}>
                                        <div className="w-100 p-2 label-float">
                                            <input type="text" className='w-100 p-2' name="name" value={name} onChange={(e) => setName(e.target.value)} placeholder=" " required autoComplete='off' />
                                            <label className='ms-3'>Name</label>
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <div className="w-100 p-2 label-float">
                                            <input type="email" className='w-100 p-2' name='email' value={email} onChange={(e) => setEmail(e.target.value)} placeholder=" " required autoComplete='off' />
                                            <label className='ms-3'>Email</label>
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <div className="w-100 p-2 label-float">
                                            <input type="phone" className='w-100 p-2' name='phone' value={phone} onChange={(e) => setPhone(e.target.value)} placeholder=" " required autoComplete='off' />
                                            <label className='ms-3'>Phone</label>
                                        </div>
                                    </Col>
                                    <Col sm={12}>
                                        <div className="w-100 p-2 label-float">
                                            <textarea className='w-100 p-2' name='message' value={message} onChange={(e) => setMessage(e.target.value)} rows="10" autoComplete='off'></textarea>
                                            <label className='ms-3'>Message</label>
                                        </div>
                                    </Col>
                                    <div className='d-flex justify-content-end align-items-end'>
                                        <RoundedButton type='submit'>Submit</RoundedButton>
                                    </div>
                                </Row>
                            </form>
                        </Col>
                    </Row>
                </div>
            </section>
        </>
    )
}

export default ContactUs
