import React, { useEffect, useState } from 'react';

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { test1, test2, test3, test4, test5 } from '../../assets/images';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { LuQuote } from 'react-icons/lu'

const Testimonial = () => {

    const [screeWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    })

    const testimonials = [
        {
            name: 'Rajesh Sharma',
            desc: "Your CRM system has greatly improved our customer interactions. It's user-friendly and the integration with the HRMS system has streamlined our internal processes as well. Kudos to the team!",
            profilImg: test1,
            designation: "Managing Partner",
            rating: 5
        },
        {
            name: 'John Smith',
            desc: "Your CRM and HRMS combo is a game-changer for our business. The data organization, automation, and analytics tools have given us a competitive edge. We're impressed with the support and continuous improvements.",
            profilImg: test2,
            designation: "Sales Director",
            rating: 5
        },
        {
            name: 'Emily Johnson',
            desc: "As a medium-sized company, we needed a powerful solution to manage our relationships and workforce. Your CRM and HRMS system exceeded our expectations. The ability to track customer interactions and employee performance in one place is invaluable.",
            profilImg: test3,
            designation: "Vice President of Sales",
            rating: 4
        },
        {
            name: 'Priya Patel',
            desc: "I've been using the CRM and HRMS software for a few months now, and I must say, it's transformed the way we manage our clients and employees. The ease of access to information and the reporting features are fantastic.",
            profilImg: test5,
            designation: "Business Development Director",
            rating: 5
        },
        {
            name: 'David Williams',
            desc: "Having used multiple CRM systems before, I can confidently say that your software stands out. The integration with the HRMS module ensures a seamless flow of data, making it easier to make informed decisions.",
            profilImg: test4,
            designation: "Operations Manager",
            rating: 3
        },
        {
            name: 'Sarah Turner',
            desc: "The CRM and HRMS integration has improved our company's efficiency. From client communication to employee leave management, everything is smoother. The reporting capabilities help us identify trends and plan ahead.",
            profilImg: test1,
            designation: "Sales Manager",
            rating: 5
        },
    ];

    const totalStars = ['', '', '', '', ''];

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 10,
        slidesToShow: screeWidth < 970 ? 1 : 2,
        slidesToScroll: 2,
        centerMode: false,
        autoplay: true,
    };

    return (
        <section className='testimonials-main-container'>
            <div className='testimonials-main my-5 d-flex justify-content-center align-items-center flex-column'>
                <div className='testimonial-head text-center d-flex justify-content-center align-items-center flex-column'>
                    <h2 className='text-center dark-title'>What our clients are saying</h2>
                    <p className='paragraph w-75'>Outlived no dwelling denoting in peculiar as he believed. Behaviour excellent middleton be as it curiosity departure ourselves very extreme future.</p>
                </div>
                <div className='testimonials-section my-5 d-flex justify-content-center align-items-center'>
                    <Slider {...sliderSettings} className='testimonial-slider'>
                        {
                            testimonials.map((data, index) => (
                                <div className='bg-white col-sm-12' key={index}>
                                    <div className='single-testimonial py-5 ps-4 pe-3 mb-5'>
                                        <div className='text-center'>
                                            <LuQuote className="background-quatoe" />
                                        </div>
                                        <div className='pe-4 single-testimonial-main-container'>
                                            <div className='d-flex justify-content-start align-items-start'>
                                                <img src={data.profilImg} alt='' className='testimonial-client-profile-image rounded-circle' />
                                                <div className='profile-section ms-4 w-75'>
                                                    <h3 className='fw-bold text-white m-0 p-0'>{data.name}</h3>
                                                    <span className='text-white m-0 p-0 fw-bold client-designation'>{data.designation}</span>
                                                </div>
                                                <div className='text-end w-5'>
                                                    <LuQuote className="quote-icon" />
                                                </div>
                                            </div>
                                            <div className='testimonial-desscription ms-2 py-3'>
                                                <p className='text-white m-0 p-0 testimonial-desc'>{data.desc}</p>
                                            </div>
                                            <div className='testimonial-footer mt-3 d-flex justify-content-between align-items-center'>
                                                <span className='text-white'>Code Quality</span>
                                                <div className='rating text-white'>
                                                    {
                                                        totalStars.map((stars, starIndex) => (
                                                            data.rating > starIndex ? (
                                                                <AiFillStar className='text-warning' key={starIndex} />
                                                            ) : (
                                                                <AiOutlineStar className='text-warning' key={starIndex} />
                                                            )
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </Slider>
                </div>
            </div>
        </section>
    )
}

export default Testimonial
