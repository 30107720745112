import './App.css';
import MainRoutes from './routes/main-routes';
import './assets/css/responsive.css';
import './assets/css/main.css';
// import './assets/css/responsive.css';
import './assets/css/fontawesome-all.min.css';
import './assets/css/icons.css';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <div className="App">
      <Toaster />
      <MainRoutes />
    </div>
  );
}

export default App;
