import React, { useEffect } from 'react'
import { cubicle2, dashboard } from '../../assets/images'
import TypewriterComponent from 'typewriter-effect';
import { Tilt } from 'react-tilt';
import { Link } from 'react-router-dom';
import { WhiteButton } from '../../components/Rounded-Button';

const Banner = () => {

    const typeWriterString = ['Invoicing', 'Firm Management', 'Client Management', 'Lead Management'];

    const defaultOptions = {
        reverse: true,
        max: 15,
        perspective: 1000,
        scale: 1.01,
        speed: 1000,
        transition: false,
        axis: null,
        reset: true,
        easing: "cubic-bezier(.03,.98,.52,.99)",
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <>
            <div className='my-5 py-5'>
                <section className="banner-one mt-5 pt-5" id="banner">
                    <span className="banner-one__shape-1"></span>
                    <span className="banner-one__shape-2"></span>
                    <span className="banner-one__shape-3"></span>
                    {/* <span className="banner-one__shape-4"></span> */}
                    <div className="container">
                        <div className="row mb-5 pb-5" style={{ zIndex: -1 }}>
                            <div className="col-xl-6 col-lg-8">
                                <div className="banner-one__content">
                                    <h1 className="text-dark banner-one__title" >We Deliver the <br />
                                        <span className='yellow-bg'>Software</span> you <br /> Need.</h1>
                                    <div className='typewriter mb-5 text-dark fs-1'>
                                        <TypewriterComponent
                                            options={{
                                                strings: typeWriterString,
                                                autoStart: true,
                                                loop: true,
                                                delay: 50
                                            }}
                                        />
                                    </div>
                                    <Link to='/subscribe'>
                                        <WhiteButton>Get Started</WhiteButton>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-3 pt-5" data-aos="zoom-in">
                                <div className="orbit d-none-sm">
                                    <div className="scrollme start-or wow zoomIn" data-wow-delay=".2s">
                                        <div className=" animateme" data-when="exit" data-from="0" data-to=".9" data-scale="0" data-opacity="0">
                                            <div className='banner-orbit-main-container'>
                                                <div className="main-orbit">
                                                    <span className="sm-circle"></span>
                                                    <img src={cubicle2} alt="logo" style={{ width: "10rem", height: "10rem" }} />
                                                    <span className="mid-circle"></span>
                                                    <span className="outer-circle"></span>
                                                    <div className="outer_text scrollme clock-spin">
                                                        <span className="scrollme clock-spin">
                                                            <span className="circle_child animateme" data-when="exit" data-from="0" data-to=".4" data-scale="0" data-opacity="0"></span>
                                                        </span>
                                                        <span className="scrollme">
                                                            <span className="circle_child animateme" data-when="exit" data-from="0" data-to=".4" data-scale=".6" data-opacity="0"></span></span>
                                                        <span className="scrollme">
                                                            <span className="circle_child animateme" data-when="exit" data-from="0" data-to=".4" data-scale=".6" data-opacity="0"></span>
                                                        </span>
                                                        <span className="scrollme">
                                                            <span className="circle_child animateme" data-when="exit" data-from="0" data-to=".4" data-scale=".6" data-opacity="0"></span>
                                                        </span>
                                                        <span className="scrollme">
                                                            <span className="circle_child animateme" data-when="exit" data-from="0" data-to=".4" data-scale=".6" data-opacity="0"></span>
                                                        </span>
                                                        <span className="scrollme">
                                                            <span className="circle_child animateme" data-when="exit" data-from="0" data-to=".4" data-scale=".6" data-opacity="0"></span>
                                                        </span>
                                                        <span className="scrollme">
                                                            <span className="circle_child animateme" data-when="exit" data-from="0" data-to=".4" data-scale=".6" data-opacity="0"></span>
                                                        </span>
                                                        <span className="scrollme">
                                                            <span className="circle_child animateme" data-when="exit" data-from="0" data-to=".4" data-scale=".6" data-opacity="0"></span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='my-5 pt-5 container banner-dashboard d-flex justify-content-center align-items-center'>
                        <Tilt options={defaultOptions}>
                            <img src={dashboard} alt='' className='mt-5 dashboard-image' style={{ transition: "all 0.3s ease-in-out", borderRadius: "10px", boxShadow: '#00000085 0px 6px 100px -41px', width: '70vw', height: "70vh" }} />
                        </Tilt>
                    </div>
                </section>
                {/* <div className='dashboard-container'></div> */}
            </div>
        </>
    )
}

export default Banner
