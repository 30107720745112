import React from 'react'

export const RoundedButton = ({ extra, children, ...rest }) => {

    return (
        <button className='rounded-button' {...rest}>
            <span className='btn-title'>{children}</span>
        </button>
    )
};

export const WhiteButton = ({ children }) => {
    return (
        <button type='button' className='white-button'>
            <span className='white-btn-title'>{children}</span>
        </button>
    )
};
