import React, { useState } from 'react'
import { cubicle3 } from '../assets/images';
import { Link } from 'react-router-dom';
import { FiGithub, FiInstagram, FiLinkedin, FiTwitter } from 'react-icons/fi';
import { Col, Row } from 'react-bootstrap';
import { LuMail } from 'react-icons/lu';
import { RiSendPlaneLine } from 'react-icons/ri'
import { Toaster } from 'react-hot-toast';
import { ErrorToast, SuccessToast } from './toast';
const Footer = () => {

  const [email, setEmail] = useState('');

  const socialIcons = [
    {
      title: 'Instagram',
      icon: <FiInstagram />,
      path: '/',
      hoverClass: 'instagram-icon',
    },
    {
      title: 'Linkedin',
      icon: <FiLinkedin />,
      path: '/',
      hoverClass: 'linkedin-icon',
    },
    {
      title: 'Twitter',
      icon: <FiTwitter />,
      path: '/',
      hoverClass: 'twitter-icon',
    },
    // {
    //   title: 'Youtube',
    //   icon: <FiYoutube />,
    //   path: '/',
    //   hoverClass: 'youtube-icon',
    // },
    {
      title: 'GitHub',
      icon: <FiGithub />,
      path: '/',
      hoverClass: 'github-icon',
    },
  ];

  const quickLinks = [
    {
      title: 'Features',
      path: '/features'
    },
    {
      title: 'Our Pricing',
      path: '/pricing'
    },
    {
      title: 'Contact Us',
      path: '/contact'
    },
    {
      title: 'Get Started',
      path: '/subscribe'
    }
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email === '') {
      const message = 'Please provide a valid email address'
      ErrorToast(message);
    } else {
      const message = 'Submitted'
      SuccessToast(message)
      setEmail('');
    }
  }

  return (
    <footer className='py-5 footer-main-container'>
      <Toaster />
      <Row className='container'>
        <Col lg={3} md={6} sm={6} className='footer-section'>
          <img src={cubicle3} alt='' className='d-block m-0 p-0 footer-logo' />
          <p className='paragraph footer-about-us fw-bold my-4'>
            Empowering Connections, Enriching Relationships: Your Journey with Our CRM Solution
          </p>
          <div>
            <span className='social-links'>
              {
                socialIcons.map((data, index) => (
                  <Link to={data.path} className={`${data.hoverClass} social-icon`} key={index}>
                    {data.icon}
                  </Link>
                ))
              }
            </span>
          </div>
        </Col>
        <Col lg={3} md={6} sm={6} className='px-5 footer-section'>
          <h5 className='dark-title'>Contact</h5>
          <div className='d-flex justify-content-start align-items-center'>
            <p className='paragraph m-0 p-0 me-2 footer-contact-text'>Phone: </p>
            <p className='paragraph m-0 p-0 footer-contact-text'>+13106518330</p>
          </div>

          <div className='d-flex justify-content-start align-items-center'>
            <p className='paragraph m-0 p-0 me-2 footer-contact-text'>Email: </p>
            <p className='paragraph m-0 p-0 footer-contact-text'>info@cubicleweb.com</p>
          </div>

          {/* <div className=''>
            <p className='d-block paragraph mb-0 mt-2 footer-contact-text'>Address: </p>
            <p className='paragraph footer-contact-text'>123, Shipra path, Mansarovar, Jaipur</p>
          </div> */}
        </Col>
        <Col lg={2} md={6} sm={6} className='px-5 footer-section'>
          <h5 className='dark-title'>Quick Links</h5>
          <ul className='m-0 p-0 footer-menu-section' style={{ listStyleType: "none" }}>
            {
              quickLinks.map((data, index) => (
                <li className='m-0 p-0' key={index}>
                  <Link to={data.path} className='footer-menu-item'>{data.title}</Link>
                </li>
              ))
            }
          </ul>
        </Col>
        <Col lg={4} md={6} sm={6} className='ps-5 pe-0 footer-section footer-newsletter-section'>
          <h5 className='dark-title'>Subscribe to our <span className='footer-newsletter-text'>newsletter</span></h5>
          <form onSubmit={handleSubmit}>
            <div className='newsletter-form-container'>
              <div className='px-5 w-100 rounded-pill bg-white newsletter-form-section '>
                <input className='p-2 border-0 bg-white newsletter-input-field' type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Your email' />
              </div>
              <span className='newsletter-mail-icon-container'>
                <LuMail className="newsletter-mail-icon fs-2 m-0 p-0" />
              </span>
              <button type='submit' className='newsletter-form-submit-btn border-0 rounded rounded-circle d-flex justify-content-center align-items-center'>
                <RiSendPlaneLine className="fs-4 text-white text-center w-100" />
              </button>
            </div>
          </form>
        </Col>
      </Row>
      <div className='container mt-5 d-flex justify-content-center align-items-center footer-bottom'>
        <small>© Cubicleweb <span id="year"></span>, All rights reserved</small>
      </div>
    </footer>
  )
}

export default Footer
