import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { cubicle3 } from '../assets/images';
import { RoundedButton } from './Rounded-Button';
import { GiHamburgerMenu } from 'react-icons/gi';
import { Nav } from 'react-bootstrap';

const Navbar = () => {

  const [sticky, setSticky] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const [screenWidth, setScreeWidth] = useState(window.innerWidth);
  const breakPoint = 970;

  const location = useLocation();

  useEffect(() => {
    const handleResize = () => setScreeWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  });

  useEffect(() => {
    breakPoint < screenWidth && setShowMobileMenu(false);
  }, [screenWidth]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 70) {
      setSticky(true);
    } else if (window.scrollY < 70) {
      setSticky(false);
    }
  };

  const menuItems = [
    {
      title: 'Home',
      path: '/'
    },
    {
      title: 'Features',
      path: '/features'
    },
    {
      title: 'Pricing',
      path: '/pricing'
    },
    {
      title: 'Contact',
      path: '/contact'
    }
  ];


  return (
    <div className='navigation-main-contianer'>
      <header className="main-nav__header-one m-0 p-0 ">
        <Nav className={sticky ? "header-navigation stricky stricked-menu stricky-fixed" : "header-navigation stricky"}>
          <div className="container main-nav">
            <div className="main-nav__logo-box">
              <Link to='/' className='main-nav__logo'>
                <img src={cubicle3}
                  style={{ width: "10rem", height: "3.38rem" }}
                  alt=""
                  className=''
                />
              </Link>
            </div>
            <div className={`${showMobileMenu ? 'mobile-nav-new ' : 'main-nav__main-navigation w-75'} ps-5`}>
              <ul className={`${showMobileMenu ? '' : 'd-flex justify-content-start align-items-center w-100'} one-page-scroll-menu main-nav__navigation-box  w-100`}>
                {
                  menuItems.map((data, index) => (
                    <Link to={data.path} key={index} className={` ${data.path === location.pathname ? 'active-tab' : 'inactive-tab'}`}>
                      <li className={` ${data.path === location.pathname ? '' : ''} scrollToLink `}>
                        <span className={`${data.path === location.pathname ? 'active-nav-link' : ''}`}>{data.title}</span>
                      </li>
                    </Link>
                  ))
                }
              </ul>
            </div>
            <div className="main-nav__right d-flex justify-content-between align-items-center">
              {
                location.pathname === '/subscribe' ? null : (
                  <Link to='/subscribe'>
                    <RoundedButton>Get Started</RoundedButton>
                  </Link>
                )
              }
              <GiHamburgerMenu className="text-dark fs-3 navigation-toggle" onClick={() => setShowMobileMenu(!showMobileMenu)} />
            </div>
          </div>
        </Nav >
      </header >
    </div >
  )
}

export default Navbar
