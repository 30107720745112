import React, { useEffect } from 'react';
import { BsCheckLg } from 'react-icons/bs';
import { RxCross2 } from 'react-icons/rx';
import { Link } from 'react-router-dom';
import Subheader from '../components/subheader';
import { pricingBanner, pricingLine } from '../assets/images';
import { RoundedButton, WhiteButton } from '../components/Rounded-Button';

const Pricing = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const toggle = true;

    const dummyArr = [
        {
            id: 1,
            price: "$20.00",
            title: "Basic Pack",
            status: "month"
        },
        {
            id: 2,
            price: "$30.00",
            title: "Standard Pack",
            status: "month"
        },
        {
            id: 3,
            price: "$40.00",
            title: "Ultimate Pack",
            status: "month"
        },
    ];

    const dummyFeatures = [" Users profile", "Task Timeline", "Corps", "Daily reporting", "HRMS", "CRM", "Kanban Board"];

    return (
        <>
            <Subheader bgImg={pricingBanner} title="Pricing" />
            <section className="pricing-one py-5" id="pricing">
                <div className="container my-5 pb-5">
                    <div className=""></div>
                    <div className=" text-center">
                        <span className="block-title__bubbles"></span>
                        <p className='paragraph'>Our Pricing Tables</p>
                        <h2 className='dark-title'>Choose Pricing Plans</h2>
                    </div>
                    <div className="tabed-content">
                        <div id="month" style={{ display: toggle ? 'block' : 'none' }}>
                            <div className="row my-5 d-flex justify-content-center align-items-center">
                                {
                                    dummyArr.map((data, i) => (
                                        <div className="col-lg-4 col-md-8 mb-5 wow fadeInLeft" data-wow-duration="1500ms" key={i}>
                                            <div className={`${i === 1 ? "pricing-default" : "pricing-one__single"}`} style={{ borderRadius: "10px" }}>
                                                <div className="pricing-one__inner">
                                                    <div className="priceHead m-0 p-0">
                                                        <h3 className='fs-bold pt-5 priceTag' style={{}}>{data.price}</h3>
                                                        <p className='mb-0 pb-5'>{data.title}</p>
                                                    </div>
                                                    <ul className="list-unstyled pricing-one__list">
                                                        {
                                                            dummyFeatures.map((data, index) => (
                                                                <li key={index} className="d-flex justify-content-center align-items-center w-100">
                                                                    <span className='row w-100'>
                                                                        <span className='col-sm-5 text-end m-0 p-0 pricing-icon'>
                                                                            {
                                                                                index === 0 || index === 1 || index === 2 || index === 3 ? (
                                                                                    <BsCheckLg className="me-3 fs-4 fw-bold text-success" />
                                                                                ) : (
                                                                                    <RxCross2 className="me-3 fs-4 fw-bold text-danger" />
                                                                                )
                                                                            }
                                                                        </span>
                                                                        <span className='col-sm-7 text-start m-0 p-0 pricing-offer'>
                                                                            {data}
                                                                        </span>
                                                                    </span>
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                    <Link to='/subscribe'>
                                                        <RoundedButton>Get Started</RoundedButton>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Pricing
