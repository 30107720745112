import React from 'react'
const Subheader = ({ bgImg, title }) => {
    return (
        <div className="transition-none">
            <section className="title-hero-bg parallax-effect" style={{ backgroundImage: `url(${bgImg})`, backgroundAttachment: "fixed" }}>
                <div className="subheader-main text-center white-color">
                    <h1 className="subheader-title">{title}</h1>
                </div>
            </section>
        </div>

    )
}

export default Subheader
